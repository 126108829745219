import React from "react"
import PropTypes from "prop-types"

import {
  HeaderHeight,
  HeaderPaddingTopDesktop,
} from "../shared/constants/layout"

const heroPaddingTop = theme => ({
  paddingTop: `2rem`,
  [theme.mediaQueries.phablet]: {
    paddingTop: `4rem`,
  },
  [theme.mediaQueries.desktop]: {
    paddingTop: `6rem`,
  },
})

const heroInvertedPaddingTop = theme => ({
  paddingTop: `calc(2rem + ${HeaderHeight})`,
  [theme.mediaQueries.phablet]: {
    paddingTop: `calc(8rem + ${HeaderHeight})`,
  },
  [theme.mediaQueries.desktop]: {
    paddingTop: `calc(6rem + ${HeaderHeight} + ${HeaderPaddingTopDesktop})`,
  },
})

const HeroContainer = ({ children, isInverted, ...rest }) => (
  <div
    css={theme => [
      { position: `relative` },
      isInverted ? heroInvertedPaddingTop(theme) : heroPaddingTop(theme),
    ]}
    {...rest}
  >
    {children}
  </div>
)

HeroContainer.propTypes = {
  isInverted: PropTypes.bool,
}

export default HeroContainer
