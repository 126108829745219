import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import OneColumnLandingPage from "./one-column"
import TwoColumnLandingPage from "./two-column"
import TwoColumnDarkHeaderLandingPage from "./two-column-dark-header"
import Layout from "../../layout"

export { ContentfulHead as Head } from "../../head"

const layoutToTemplate = {
  "One Column": OneColumnLandingPage,
  "One Column Full Width Image": OneColumnLandingPage,
  "Two Columns": TwoColumnLandingPage,
  "Two Columns Dark Header": TwoColumnDarkHeaderLandingPage,
}

const LandingPage = ({ data, location }) => {
  const { contentfulLandingPage } = data
  const { layout } = contentfulLandingPage

  const LandingPageBody = layoutToTemplate[layout]

  return (
    <Layout pathname={location.pathname}>
      <div css={theme => ({ marginBottom: theme.space[15] })}>
        <LandingPageBody contentfulLandingPage={contentfulLandingPage} />
      </div>
    </Layout>
  )
}

LandingPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default LandingPage

export const pageQuery = graphql`
  query LandingPage($id: String!) {
    contentfulLandingPage(id: { eq: $id }) {
      name
      slug
      layout
      landingPageType
      title
      subtitle {
        subtitle
      }
      illustration {
        title
        fluid: gatsbyImage(layout: CONSTRAINED, width: 1440)
        file {
          url
        }
      }
      socialMediaImage {
        file {
          url
        }
      }
      primaryCopy {
        childMarkdownRemark {
          html
        }
      }
      secondaryCopy {
        childMarkdownRemark {
          html
        }
      }
      formHeader {
        formHeader
      }
      form {
        ... on ContentfulMarketoForm {
          id
          marketoFormCampaignObject {
            formId
            name
          }
          thankYouMessage {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulHubSpotForm {
          portalId
          formId
          sfdcCampaignId
          goToWebinarWebinarKey
        }
      }
    }
  }
`
