import React from "react"

import { guideContentBlockStyles, articleStyles } from "../../utils/styles"
import checkmark from "../../assets/checkmark.svg"

const Copy = ({ html }) => (
  <div
    className="lp-content"
    css={theme => ({
      ...guideContentBlockStyles(theme),
      ...articleStyles(theme),
      "& .lp-content ul": {
        listStyleImage: `url(${checkmark})`,
      },
    })}
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
)

export default Copy
