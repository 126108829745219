import React from "react"
import HubspotForm from "./HubspotForm"
import MarketoForm from "./MarketoForm/"
import { useLocation } from "@gatsbyjs/reach-router"

export default function MarketingForm({ form, wrapperCss }) {
  const { pathname } = useLocation()

  if (!form) return null

  const netlifyFormRX = new RegExp(
    "contact-us|contact-sales|products/concierge"
  )
  const isNetlifyFormPage = netlifyFormRX.test(pathname)

  if (process.env.GATSBY_NETLIFY_MARKETING_FORMS && isNetlifyFormPage) {
    return (
      <HubspotForm
        region="na1"
        portalId="7477936"
        formId="c941c94a-e642-4f87-bc45-50d2f2a2c980"
        sfdcCampaignId="7013w000001zH6CAAU"
        redirectUrl="/thank-you/contact-demo-all/"
        css={theme => ({
          width: `100%`,
          [theme.mediaQueries.tablet]: {
            width: `auto`,
            minWidth: `30rem`,
          },
        })}
      />
    )
  } else if (form.marketoFormCampaignObject) {
    const { formId } = form.marketoFormCampaignObject
    const messageOnSubmit = form.thankYouMessage?.childMarkdownRemark?.html
    return (
      <MarketoForm
        formId={formId}
        messageOnSubmit={messageOnSubmit}
        wrapperCss={wrapperCss}
      />
    )
  } else {
    return (
      <HubspotForm
        {...form}
        wrapperCss={wrapperCss}
        css={{
          "& .hs-form": {
            padding: 0,
          },
        }}
      />
    )
  }
}
