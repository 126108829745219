import React from "react"

import { smallCapsStyles } from "../../utils/styles"

const LandingPageType = ({ children, isInverted }) => (
  <div
    css={theme => ({
      ...smallCapsStyles(theme),
      color: isInverted ? theme.colors.purple[30] : false,
      marginBottom: theme.space[5],
    })}
  >
    {children}
  </div>
)

export default LandingPageType
