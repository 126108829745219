import React from "react"
import PropTypes from "prop-types"

const Polycircle = () => (
  <svg
    preserveAspectRatio="none"
    width="1440"
    height="160"
    viewBox="0 0 1440 160"
  >
    <g>
      <path
        d="M44.5344954,0 L231.324518,83.8855458 L547.145466,140.743989 L892.854534,140.743989 L1208.67548,83.8855458 L1395.4655,-6.03961325e-14 L1440,0 L1440,160 L0,160 L0,0 L44.5344954,0 Z"
        id="Combined-Shape"
        fill="#FFFFFF"
      />
    </g>
  </svg>
)

const Wave = ({ fill }) => (
  <svg viewBox="0 0 1440 160">
    <g>
      <path
        fill={fill}
        d="M0,160 L0,0 C548.949848,3.38469e-14 823.425766,157.977 1440,157.977 L1440,160 L0,160 Z"
        transform="matrix(-1 0 0 1 1440 0)"
      />
    </g>
  </svg>
)

Wave.propTypes = {
  fill: PropTypes.string,
}

const Round = ({ fill }) => (
  <svg
    preserveAspectRatio="none"
    width="1440"
    height="40"
    viewBox="0 0 1440 40"
  >
    <g fill={fill}>
      <path d="M0,30.0130265 C239.658854,10.0043422 479.143229,3.07450003e-15 718.453125,0 C957.763021,0 1198.27865,10.0043422 1440,30.0130265 L1440,40 L0,40 L0,30.0130265 Z" />
    </g>
  </svg>
)

Round.propTypes = {
  fill: PropTypes.string,
}

const Angle = ({ fill }) => (
  <svg viewBox="0 0 100 100" preserveAspectRatio="none">
    <polygon fill={fill} points="0,100 100,0 100,100" />
  </svg>
)

Angle.propTypes = {
  fill: PropTypes.string,
}

const SkewedBackground = ({
  background = `transparent`,
  fill,
  position = `bottom`,
  shape = `wave`,
  height,
}) => (
  <div
    className="skewed-background"
    css={theme => ({
      position: `absolute`,
      top: position === `top` ? -1 : `auto`,
      bottom: position === `bottom` ? -1 : `auto`,
      left: 0,
      right: 0,
      width: `100%`,
      // height: height ? height : false,
      zIndex: 2,
      background: background,
      pointerEvents: `none`,
      userSelect: `none`,
      "& svg": {
        zIndex: 0,
        position: `absolute`,
        top: position === `top` ? -1 : `auto`,
        bottom: position === `bottom` ? -1 : `auto`,
        height: shape === `angle` && height ? `${height}` : false,
        left: 0,
        right: 0,
        width: `100%`,
        transformOrigin: `50% 50%`,
        transform: position === `top` ? `scale(1, -1)` : false,
        fill: fill ? fill : theme.colors.white,
      },
    })}
  >
    {
      {
        round: <Round fill={fill} />,
        wave: <Wave fill={fill} />,
        angle: <Angle fill={fill} />,
        polycircle: <Polycircle fill={fill} />,
      }[shape]
    }
  </div>
)

SkewedBackground.propTypes = {
  background: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  position: PropTypes.oneOf([`top`, `bottom`]),
  shape: PropTypes.oneOf([`angle`, `wave`, `round`]),
}

export default SkewedBackground
