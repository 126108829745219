import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

import LandingPageType from "./landing-page-type"
import Copy from "./copy"
import Title from "../../components/hero/title.js"
import Subtitle from "../../components/hero/subtitle.js"
import MarketingForm from "../../components/marketing-forms/MarketingForm"
import Container from "../../components/container"

const TwoColumnLandingPage = ({ contentfulLandingPage }) => {
  const {
    landingPageType,
    title,
    subtitle,
    illustration,
    primaryCopy,
    secondaryCopy,
    formHeader,
    form,
  } = contentfulLandingPage

  return (
    <Container css={theme => ({ marginTop: theme.space[10] })}>
      <div
        css={theme => ({
          marginLeft: `auto`,
          marginRight: `auto`,
          marginTop: theme.space[13],
          maxWidth: `36rem`,
          [theme.mediaQueries.desktop]: {
            marginLeft: 0,
            marginRight: 0,
            maxWidth: `none`,
            display: `flex`,
          },
        })}
      >
        <div
          css={theme => ({
            width: `100%`,
            [theme.mediaQueries.desktop]: {
              width: `60%`,
              marginRight: theme.space[11],
              flexShrink: 0,
            },
          })}
        >
          {landingPageType && (
            <LandingPageType>{landingPageType}</LandingPageType>
          )}
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle.subtitle}</Subtitle>}
          {primaryCopy && <Copy html={primaryCopy.childMarkdownRemark.html} />}
          {secondaryCopy && (
            <Copy html={secondaryCopy.childMarkdownRemark.html} />
          )}
        </div>
        <div
          css={theme => ({
            width: `100%`,
            [theme.mediaQueries.desktop]: { marginLeft: theme.space[5] },
          })}
        >
          {illustration && (
            <GatsbyImage
              image={illustration.fluid}
              alt={illustration.title}
              css={theme => ({ marginBottom: theme.space[7] })}
            />
          )}
          <div css={{ display: `flex`, flexDirection: `column` }}>
            {formHeader?.formHeader && (
              <h3 css={theme => ({ color: theme.colors.gatsby })}>
                {formHeader?.formHeader}
              </h3>
            )}
            <MarketingForm form={form} />
          </div>
        </div>
      </div>
    </Container>
  )
}

TwoColumnLandingPage.propTypes = {
  contentfulLandingPage: PropTypes.object.isRequired,
}

export default TwoColumnLandingPage
