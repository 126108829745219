import React from "react"
import PropTypes from "prop-types"

import { subtitleStyles } from "../../utils/styles"

const Subtitle = ({ children, isInverted, ...rest }) => {
  return (
    <h2
      css={theme => [
        subtitleStyles(theme),
        isInverted && { color: theme.colors.white },
      ]}
      {...rest}
    >
      {children}
    </h2>
  )
}

Subtitle.propTypes = {
  isInverted: PropTypes.bool,
}

export default Subtitle
